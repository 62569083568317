
.circular-loader {
  position: relative;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  z-index: 50;
  height: 2rem;
  width: 2rem;
  z-index: 50;
}

.circular-loader svg {
  animation: rotate 2s linear infinite;
}

.circular-loader circle {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke: rgb(var(--main));
  animation: dash 1.5s ease-in-out infinite;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
  color: rgb(var(--main));
}

.status-text {
  position: absolute;
  top: 120%;
  left: calc(50% - 5rem);
  font-size: 0.8rem;
  width: 10rem !important;
  text-align: center;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: -124;
  }
}
