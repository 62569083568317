
.page-header {
	display: flex;
	position: sticky;

	flex-wrap: wrap;

	width: 100%;
	height: 6rem;


  top:0;  

	justify-content: space-between;
	align-items: center;
	
	.header-logo img { height: 1.5rem;} 

	transition: all 0.33s cubic-bezier(.08,.75,.77,1.03);
}

