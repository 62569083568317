
@import './main.css';
@import './style.css';

.rotate {
  animation: rotate 5s linear infinite;
  transform-origin: center center;
  transform-box: border-box;
}

:root {
  ////////////////////////////////////////
  // 1.COLORS VARIABLES 
  ///////////////////////////////////////
  // Main and secondary colors
  --main:         48,213,200; 
  --main-light:   rgba(--main,  0.1);
  --second:       34,102,255;
  --second-light: rgba(--second, 0.1);
  // BW
  --black: 0, 0, 0;
  --white: 255, 255, 255;
  --bw-extra:         color-mix(in srgb, var(--black) 12.5%,  var(--white));
  --bw-big:           color-mix(in srgb, var(--black) 25.0%,  var(--white));
  --bw-semi:          color-mix(in srgb, var(--black) 37.5%,  var(--white));
  --bw-medium:        color-mix(in srgb, var(--black) 50.0%,  var(--white));
  --bw-regular:       color-mix(in srgb, var(--black) 62.5%,  var(--white));
  --bw-small:         color-mix(in srgb, var(--black) 75.0%,  var(--white));
  --bw-thin:          color-mix(in srgb, var(--black) 87.5%,  var(--white));
  // Utils
  --transparent:   rgba(0,0,0,0);
  ////////////////////////\////////////////
  // 2.SIZE VARIABLES //
  ///////////////////////////////////////
  --extra:        5.00rem;
  --big:          2.50rem; 
  --semi:         1.50rem;
  --medium:       1.25rem;
  --regular:      1.00rem;
  --small:        0.75rem;
  --thin:         0.50rem;
  --micro:   0.25rem;
  ////////////////////////////////////////
  // 3. TEXT VARIABLES
  ///////////////////////////////////////
  --font: 'Helvetica Neue';
  ////////////////////////////////////////
  // 4. BREAKPOINTS
  ///////////////////////////////////////
  --super-ultra-tiny-flip-phone-max:  240px;
  --flip-phone-max:                   320px;
  --phone-portrait-max:               321px;
  --phone-landscape-max:              640px;
  --tablet-portrait-min:              641px;
  --tablet-portrait-max:              768px;
  --tablet-landscape-min:             769px;
  --tablet-landscape-max:             1024px;
  --desktop-min:                      1025px;
  --desktop-max:                      1440px;
  --xl-desktop-min:                   1441px;
  --xl-desktop-max:                   1920px;
  --xxl-desktop-min:                  1921px;
}

html, body {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--white),1) !important;
  color: rgba(var(--black),1)  !important; 
  font-family: var(--font) !important; 
}

#app {
  display: block;
  height: auto;
}

